<template>
  <div class="ayuda">

    <headNav :activeNav="'ayuda'"></headNav>

    <div class="banner"></div>
    <div class="mainContent">
      <div class="question_content">
        <div class="quesionItem">
          <div class="pro"> ¿Cómo puedo solicitar de nuevo un préstamo?</div>
          <div class="answer">Entra a la app y da clic en el botón naranja que dice solicitar ahora. Te pedirá que aceptes los términos y condiciones del préstamo, elijas el monto y selecciones la cuenta a la que haremos el depósito.</div>
        </div>
        <div class="quesionItem">
          <div class="pro">¿Cuánto tiempo tardan en depositar?</div>
          <div class="answer">Una vez que eres aprobado el depósito se manda en cuestión de minutos, sin embargo, como los horarios bancarios en México son de Lunes a Viernes de 9 a 5 (excepto días festivos), tu banco podría tardar un poco en reflejarlo en tu cuenta. ¡No te preocupes! Verifica tu cuenta al día siguiente ¡y listo!</div>
        </div>
        <div class="quesionItem">
          <div class="pro">¿En cuanto tiempo me dan un segundo préstamo?</div>
          <div class="answer">Una vez que se acredite tu pago podrás solicitar un segundo préstamo ¡de inmediato!</div>
        </div>
        <div class="quesionItem">
          <div class="pro">¿Qué plazo tengo para pagar?</div>
          <div class="answer">Podrás pagar a los 07 días o a los 14 días según la opción que elijas. Puedes hacer pagos adelantados sin ningún problema, pero deberás liquidar tu préstamo de acuerdo al plazo que hayas seleccionado.</div>
        </div>
        <div class="quesionItem">
          <div class="pro">¿Puedo tener otro préstamo mientras tengo uno activo?</div>
          <div class="answer">Lamentablemente no puedes pedir otro préstamo mientras tienes uno activo. Deberás liquidar tu préstamo activo para poder solicitar otro. Eso sí, una vez que liquides, podrás solicitar otro de forma inmediata. Los montos los decide la app pero generalmente van incrementando conforme la utilices.</div>
        </div>
        <div class="quesionItem">
          <div class="pro">¿Cómo usan la información que lleno en la solicitud de préstamo? </div>
          <div class="answer">Encash  usa tus respuestas para determinar si eres candidato a un préstamo de nuestra app. Toda tu información se encuentra segura y protegida. Puedes leer nuestro Aviso de Privacidad dentro de la app.</div>
        </div>
      </div>
    </div>

    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/ayuda.scss";

export default {
  name: "ayuda",
  components: { headNav, footPage },
  data() {
    return {

    }
  },
}
</script>

<style>

</style>
