import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import sobre from "../views/sobre.vue"
import aviso from "../views/Aviso.vue"
import ayuda from "../views/ayuda.vue"
import loan from "../views/loan.vue"

//import versionTood from '../api/versionUpdate';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home",
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/about',
    name: 'about',
    component: sobre
  },
  {
    path: '/notice',
    name: 'notice',
    component: aviso
  },
  {
    path: '/help',
    name: 'help',
    component: ayuda
  },
  {
    path: '/loan',
    name: 'loan',
    component: loan
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach(async(to, form, next) => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新

  versionTood.isNewVersion();

  console.log(to)
  console.log(form)

  next()

});*/


export default router
