<template>
  <div class="footPage">
    <div class="lastPage">
      <div class="mainContent">
        <div class="title">Contáctenos</div>
        <p class="item-title" style="margin-top: 240px;text-align: center;font-size: 30px; color: #ffffff; font-weight: 500;">
          INTERVALO FINANZAS, SOCIEDAD ANÓNIMA DE CAPITAL VARIABLE
        </p>
        <!-- <div class="email">
          <img src="@/assets/image/ico_email.png" alt="" class="icoEmail">
          <p>1758267654@qq.com</p>
          <a href="" class="emailBtn">Enviar</a>
        </div> -->
        <div class="content" style="margin-top: 50px;">
          <div class="item">
            <img src="@/assets/image/ico_1.png" alt="" />
            <p class="item-title">Atención de cliente</p>
            <p class="item-subtitle">+52 55 8526 6358</p>
          </div>
          <div class="item">
            <img src="@/assets/image/ico_6.png" alt="" />
            <p class="item-title">WhatsApp Encash</p>
            <p class="item-subtitle">5561716249</p>
          </div>
          <div class="item">
            <img src="@/assets/image/ico_2.png" alt="" />
            <p class="item-title">Correo</p>
            <p class="item-subtitle">encash.cobranza@gmail.com</p>
          </div>

          <div class="item">
            <img src="@/assets/image/ico_4.png" alt="" />
            <p class="item-title">Tiempo laboral</p>
            <p class="item-subtitle">De lunes a viernes 9:00-18:40</p>
            <p class="item-subtitle">De sábado a domingo 9:00-14:40</p>
          </div>

          <div class="item">
            <img src="@/assets/image/ico_5.png" alt="" />
            <p class="item-title">Queja</p>
            <p class="item-subtitle">encash.prestamos@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="foot">京ICP备10214630 营业执照 无线电发射设备销售备案编号11201910351200</div> -->
  </div>
</template>

<script>
import "./scss/foot.scss";

export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>

<style>
</style>
