<template>
  <div class="Inicio">
    <div class="banner">
      <!-- <img class="banner_img" src="@/assets/image/bg_1.jpg" alt=""> -->
      <div class="mainContent">
        <a
          href="https://Encash.onelink.me/dDG5/buyfm168"
          target="_blank"
          class="playBtn"
          ><img src="@/assets/image/playBtn.png" alt=""
        /></a>
      </div>
    </div>

    <div class="p2">
      <div class="mainContent">
        <div class="title">Solicitar un préstamo</div>
        <div class="loan-content-box">
          <div class="loan-select-box">
            <!-- <div class="loan-select-item">7 Días</div> -->
            <div
              class="loan-select-item"
              :class="isActive == 61 ? 'selected' : ''"
              @click="getDays(61)"
            >
              61 Días
            </div>
            <div
              class="loan-select-item"
              :class="isActive == 90 ? 'selected' : ''"
              @click="getDays(90)"
            >
              90 Días
            </div>
            <div
              class="loan-select-item"
              :class="isActive == 120 ? 'selected' : ''"
              @click="getDays(120)"
            >
              120 Días
            </div>
          </div>
          <div class="moneybox">
            $ <span id="money">{{ dealNumber(sliderValue) }}</span>
          </div>
          <div class="sliderBox">
            <el-slider
              v-model="sliderValue"
              :min="1000"
              :max="20000"
              :show-tooltip="false"
            ></el-slider>
            <div class="range">
              <p>$ <span>1,000</span></p>
              <p>$ <span>20,000</span></p>
            </div>
          </div>

          <div class="dateBox">
            <img src="@/assets/image/date_icon.png" alt="" />
            <div class="date">
              Fecha de vencimiento：<span>{{ addDate }}</span>
            </div>
          </div>

          <a
            href="https://Encash.onelink.me/dDG5/buyfm168"
            target="_blank"
            class="downloadBtn"
            ><img src="@/assets/image/downloadBtn.png" alt=""
          /></a>
        </div>
      </div>
    </div>

    <div class="p3">
      <div class="mainContent">
        <div class="title">Por qué elegir Encash</div>
        <div class="content">
          <div class="item">
            <img src="@/assets/image/item1.png" alt="" />
            <p class="item-title">límite de préstamo elevado</p>
            <p class="item-subtitle">El máximo monto de préstamo</p>
            <p class="item-subtitle">es hasta 20,000 pesos</p>
          </div>
          <div class="item">
            <img src="@/assets/image/item2.png" alt="" />
            <p class="item-title">Fácil de aplicar</p>
            <p class="item-subtitle">Solo se necesita el número de</p>
            <p class="item-subtitle">celular para el préstamo</p>
          </div>
          <div class="item">
            <img src="@/assets/image/item3.png" alt="" />
            <p class="item-title">Desembolsos rápidos</p>
            <p class="item-subtitle">Aprobado en 3 minutos</p>
          </div>
        </div>
      </div>
    </div>

    <div class="p4">
      <div class="mainContent">
        <div class="title">¿Cómo solicito mi préstamo?</div>
        <img src="@/assets/image/p4_img.png" alt="" class="p4Img" />
        <div class="content">
          <div class="item">
            <p class="item-title">Descarga la App</p>
            <p class="item-subtitle">¡Únete a miles de usuarios de</p>
            <p class="item-subtitle">Encash en México! Descarga</p>
            <p class="item-subtitle">la App directamente desde</p>
            <p class="item-subtitle">Google Play</p>
            <div class="number">1</div>
          </div>
          <div class="item">
            <p class="item-title">Aplica para un préstamo</p>
            <p class="item-subtitle">Regístrate directamente en la</p>
            <p class="item-subtitle">aplicación Encash y completa</p>
            <p class="item-subtitle">tu información personal para</p>
            <p class="item-subtitle">solicitar tu primer préstamo.</p>
            <p class="item-subtitle">¡Es sencillo!</p>
            <div class="number">2</div>
          </div>
          <div class="item">
            <p class="item-title">Datos están 100% seguros</p>
            <p class="item-subtitle">Ten la seguridad de que tus datos se</p>
            <p class="item-subtitle">mantienen seguros. El equipo de</p>
            <p class="item-subtitle">Encash trabaja las 24 horas al día</p>
            <p class="item-subtitle">para que no tengasque preocuparte.</p>
            <div class="number">3</div>
          </div>
          <div class="item">
            <p class="item-title">¡Estás aprobado!</p>
            <p class="item-subtitle">¡Felicidades por tu primer préstamo</p>
            <p class="item-subtitle">de Encash !</p>
            <div class="number">4</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="AVISO IMPORTANTE"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <p>
          Te informarnos que el número de referencia o cuenta para pagar tu
          crédito es únicamente el que aparece en tu acceso a la aplicación.
          Encash nunca te contactará por otro medio para proporcionarte una
          cuenta distinta a la que se indica en tu aplicación. Para evitar que
          seas víctima de algún fraude, te sugerimos ser cuidadoso y verificar
          el número de referencia o cuenta en la que pagarás tu crédito. Para
          cualquier duda o aclaración puedes comunicarte con nuestro
          departamento de Atención a Clientes.
        </p>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "./scss/Inicio.scss";

export default {
  name: "Inicio",
  data() {
    return {
      sliderValue: 5000,
      currentdate: "",
      addDate: "",
      isActive: 61,
      dialogVisible: true,
    };
  },
  created() {
    this.getNowFormatDate();
    if (this.$route.query.dialogVisible == 1) {
      this.dialogVisible = false;
    }
  },
  methods: {
    getDays(day) {
      this.isActive = day;
      this.dateAddDays(this.currentdate, day);
    },
    dealNumber(num) {
      if (num != 0 && num) {
        num = num + "";
        let splitList = num.split(".");
        //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
        let intStrList = splitList[0]
          .split("")
          .reverse()
          .join("")
          .match(/(\d{1,3})/g);
        //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
        let intStr = intStrList.join(",").split("").reverse().join("");
        return intStr;
      }
      return num;
    },
    dateAddDays(dateStr, dayCount) {
      var tempDate = new Date(dateStr.replace(/-/g, "/")); //把日期字符串转换成日期格式
      var resultDate = new Date((tempDate / 1000 + 86400 * dayCount) * 1000); //增加n天后的日期
      var resultDateStr =
        resultDate.getFullYear() +
        "-" +
        (resultDate.getMonth() + 1) +
        "-" +
        (resultDate.getDate() - 1); //将日期转化为字符串格式
      this.addDate = resultDateStr;
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currentdate = currentdate;
      this.getDays(90);
    },
  },
};
</script>

<style>
</style>
