<template>
  <div class="Sobre">
    <headNav :activeNav="'sobre'"></headNav>

    <div class="p1"></div>
    <div class="mainContent">
      <img src="@/assets/image/about_banner.png" alt="" class="banner" />
      <div class="title">Perfil de la empresa</div>
      <div class="content clearfix">
        <div class="left">
          Encash es una plataforma que ofrece,plataforma que ofrece préstamos en
          línea rápidos, accesibles y seguros. Registrate en segundos, aplica
          para un préstamo y recibe tu efectivo en minutos.vemos el mundo
          diferente. Estamos motivados por una creencia fundamental en las
          personas, y trabajamos juntos para demostrar su potencial. Asumimos
          riesgos que otros no tomarían para poner más poder en las manos de
          nuestros clientes.
        </div>
        <div class="right">
          <img src="@/assets/image/about_img.png" alt="" />
        </div>
      </div>
    </div>

    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";

import "../components/scss/sobre.scss";

export default {
  name: "Sobre",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>

<style>
</style>
